import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/"
import SEO from "../../../components/seo/"
import Crumbs from "../../../components/breadcrumb/"
import BackNav from "../../../components/back-nav/"

import Split from "../../../components/split/"
import TextBlock from "../../../components/text-block/"
import ImageBlock from "../../../components/image-block/"

import { psColour, wlColour, siteColour } from "../../../data/data.colours"

const RectoryPage = ({data, pageContext, location}) => {
	
	const image = useStaticQuery(graphql`
		query {
			imageBg: file(relativePath: { eq: "backgrounds/sheep-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`)
	
	return (
		<>
			<SEO title="Rectory Farm"
				path={ location.pathname }/>
			<Layout>
				<Crumbs
					pageContext={pageContext}
					location={location}
					full={true}/>
				<BackNav
					hex={psColour.hex}
					link={`/painesend/our-farms`}/>
				<Split>
					<ImageBlock 
						image={ data.imageBg.childImageSharp.fluid }/>
					<TextBlock 
						title={`Rectory Farm, Puttenham`}
						titleTag={ `h1` }
						text={`
							<p>This 260-acre farm sitting within the stunning Vale of Aylesbury comprises grazing land and a group of dilapidated farm buildings. The old farmyard is the perfect setting for a rural courtyard-style development of exclusive residential properties. An exciting joint venture with Laxton Properties is in the early stages of planning with the local planning authority.</p>
						`}
						bgColour={ psColour.slug }/>

				</Split>
			</Layout>
		</>
	)
}

export default RectoryPage
